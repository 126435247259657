import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { Capacitor } from "@capacitor/core";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { APP_ROUTES } from "./app/app-routing.module";
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { CurrencyPipe } from "@angular/common";
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy, withInMemoryScrolling,
  withPreloading,
  withRouterConfig
} from "@angular/router";
import { provideNgcCookieConsent } from "ngx-cookieconsent";
import { cookieConsentConfig } from "./app/shared/constants/variables";

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AuthModule.forRoot({
        domain: environment.auth0Domain,
        clientId: environment.auth0ClientId,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        httpInterceptor: {
          allowedList: [
            {
              uri: environment.apiUrl + '*',
              allowAnonymous: true
            }
          ]
        },
        authorizationParams: {
          redirect_uri: Capacitor.isNativePlatform() ? environment.auth0CallbackUrl : environment.loginRedirectUrl,
          audience: environment.auth0Audience,
        }
      }),
      IonicModule.forRoot(),
      IonicStorageModule.forRoot(),
      HttpClientModule,
      HttpClientJsonpModule,
    ),
    provideNgcCookieConsent(cookieConsentConfig),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules), withRouterConfig({onSameUrlNavigation: 'reload'})),
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    CurrencyPipe,
  ]
}).catch(err => console.log(err));
