import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {Capacitor} from '@capacitor/core';
import {LoginService} from "../../../services/login.service";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-auth-status',
  templateUrl: './auth-status.component.html',
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class AuthStatusComponent implements OnInit {
  @Input() color: string = 'primary';

  constructor(
    public auth: AuthService,
    public loginService: LoginService,
  ) {}

  ngOnInit() {
  }
}
