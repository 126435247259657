import { ResolveFn } from "@angular/router";
import { MenuItemForListDto } from "../_dtos/menu-items/menu-items-for-list-dto";
import { inject } from "@angular/core";
import { MenuItemsService } from "../services/menu-items.service";
import { LoggerService } from "../services/logger.service";
import { LoadingService } from "../services/loading.service";

export const menuItemsResolver: ResolveFn<MenuItemForListDto[]> = async () => {
  const menuItemService = inject(MenuItemsService);
  const logger = inject(LoggerService);
  const loadingService = inject(LoadingService);

  if (menuItemService.$menuItems()?.length) return menuItemService.$menuItems();

  logger.debug('menuItemsResolver -- menu items loading menu items');
  loadingService.isLoading.set(true);
  await menuItemService.loadMenuItemsAsync();
  loadingService.isLoading.set(false);
  return menuItemService.$menuItems();
};
