import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { LogLevels } from "../_models/log-levels";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private _levelOrder: Map<LogLevels, number> = new Map([
    ['debug', 0],
    ['info', 1],
    ['warn', 2],
    ['error', 3],
    ['none', 4]
  ]);

  constructor() { }

  info(...data: any[]) {
    if (!this._isLevel('info')) return;
    console.log(data);
  }

  debug(...data: any[]) {
    if (!this._isLevel('debug')) return;
    console.log(data);
  }

  warn(...data: any[]) {
    if (!this._isLevel('warn')) return;
    console.log(data);
  }

  private _isLevel (level: LogLevels) {
    return this._levelOrder.get(environment.logLevel) <= this._levelOrder.get(level);
  }
}
