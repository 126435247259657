import {Routes} from '@angular/router';
import {HomePage} from "./pages/home/home.page";
import {authGuard} from "./_guards/auth.guard";
import {CookiePolicyComponent} from "./pages/cookie-policy/cookie-policy.component";
import {SupportComponent} from "./pages/support/support.component";
import {menuItemsResolver} from "./_resolvers/menu-items-resolver";
import {cartResolver} from "./_resolvers/cart-resolver";

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: HomePage,
        resolve: {cartItems: cartResolver},
    },
    {
        path: 'user-profile',
        loadChildren: () => import('./pages/user-profile/user-profile-routes'),
        canActivate: [authGuard]
    },
    {
        path: 'cart',
        loadChildren: () => import('./pages/cart/cart-page-routes'),
        resolve: {cartItems: cartResolver},
    },
    {
        path: 'checkout',
        loadChildren: () => import('./pages/checkout/checkout-routes'),
    },
    {
        path: 'orders',
        loadChildren: () => import('./pages/order-history/order-history-routes'),
    },
    {
        path: 'menu-item-detail',
        loadChildren: () => import('./pages/menu-item-details/menu-item-details-page-routes'),
    },
    {
        path: 'order-history',
        loadChildren: () => import('./pages/order-history/order-history-routes'),
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin-page-routes'),
        resolve: {menuItems: menuItemsResolver, cartItems: cartResolver},
    },
    {
        path: 'menu',
        loadChildren: () => import('./pages/menu-items-list/menu-items-list-page-routes'),
        resolve: {menuItems: menuItemsResolver, cartItems: cartResolver},
    },
    {
        path: 'cookie-policy',
        component: CookiePolicyComponent
    },
    {
        path: 'support',
        component: SupportComponent
    },
    {
        path: 'privacy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy-routes')
    },
    {
        path: 'terms',
        loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions-routes')
    },
];
