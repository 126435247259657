import { Component, computed, OnDestroy, OnInit } from '@angular/core';
import { IonicModule } from "@ionic/angular";
import {Subscription} from "rxjs";
import {CartService} from "../../../services/cart.service";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { LoggerService } from "../../../services/logger.service";

@Component({
  selector: 'app-header-cart-status',
  templateUrl: './header-cart-status.component.html',
  standalone: true,
  imports: [IonicModule, RouterLink, RouterLinkActive]
})
export class HeaderCartStatusComponent implements OnInit {
  count = computed(() => {
    this._logger.debug('HeaderCartStatusComponent -- computing cart count');
    return this._cart.$cartItems()?.reduce((acc, cur) => acc + cur.quantity, 0) ?? 0
  })

  constructor(
    private _cart: CartService,
    private _logger: LoggerService
  ) {}

  ngOnInit() {}
}
