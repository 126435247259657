import config from '../../capacitor.config';
import { LogLevels } from "../app/_models/log-levels";

export const environment = {
  logoutUrl: 'https://dev.yanzidelivery.com',
  loginRedirectUrl: 'https://dev.yanzidelivery.com',
  apiUrl: 'https://dev.yanzidelivery.com/api/',
  production: false,
  paypalClientId: 'Ac8QJ4tMRHnIlz1Ga0T23KKjZiSJ9hsStsQB76unbvoceOfjE6wG4cKwCo8yhfRL8yK4Qb0yf9Z3NdZ0',
  googleMapsApiKey: 'AIzaSyB5Q3uVUlDzkRrq_-sj2hCMBDnKH_xtCF8',
  placeMarkerOnMapOnLoad: true,
  auth0CallbackUrl: `${config.appId}://lido.jp.auth0.com/capacitor/${config.appId}/callback`,
  auth0Domain: 'lido.jp.auth0.com',
  auth0ClientId: 'NVc660DETF6vwHR4qdIHIpEromXQA2DJ',
  auth0Audience: 'lido-delivery-api',
  logLevel: 'debug' as LogLevels,
};
