import { ResolveFn } from "@angular/router";
import { CartItem } from "../_models/cart-items";
import { CartService } from "../services/cart.service";
import { inject } from "@angular/core";
import { LoggerService } from "../services/logger.service";

export const cartResolver: ResolveFn<CartItem[]> = async () => {
  const cartService = inject(CartService);
  if (cartService.$cartItems()) return cartService.$cartItems();

  const logger = inject(LoggerService);
  logger.debug('cartResolver -- cart items loading');
  await cartService.loadCartItemsAsync();
  return cartService.$cartItems();
};
