import { Injectable, signal } from '@angular/core';
import { Address } from "../_models/address";
import { StoreAssignments } from "../_models/store-assignments";
import { LoggerService } from "./logger.service";
import { environment } from "../../environments/environment";
import { lastValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { ValuesService } from "./values.service";

@Injectable({
  providedIn: 'root'
})
export class SelectedAddressService {
  $selectedAddress = signal<Address | undefined>(undefined);
  $storeAssignment = signal<StoreAssignments | undefined>(undefined);

  constructor(
    private _logger: LoggerService,
    private _http: HttpClient,
    private _valuesService: ValuesService
  ) {
    toObservable(this.$selectedAddress)
      .pipe(takeUntilDestroyed())
      .subscribe(async address => {
        this._logger.debug('SelectedAddressService -- selected address changed:', address);
        await this.updateAssignedStore(address);
        await this._valuesService.loadRestrictionsAsync();
      });
  }

  async updateAssignedStore (address: Address) {
    this._logger.debug('SelectedAddressService -- updating assigned store');

    if (!address) {
      this.$storeAssignment.set(undefined);
      return;
    }

    const url = environment.apiUrl + 'StoreAssignment/Calculate';

    const assignment = await lastValueFrom(this._http.get(url, {
      params: {lat: address.lat.toString(), lng: address.lng.toString()},
      responseType: 'text'
    }));

    this._logger.debug('SelectedAddressService -- got store assignment from API:', assignment);
    this.$storeAssignment.set(assignment ?? 'none');
  }
}
